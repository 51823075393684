import React from 'react';
import PropTypes from 'prop-types';
import ContentTile from '@components/DynamicComponents/ContentTile';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import s from './FourTile.module.scss';

const propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  header: PropTypes.string,
  subHeader: PropTypes.string,
};

const FourTile = ({ content, header = '', subHeader = '' }) => {
  const { verticalIndex, componentRef } = useVerticalIndex();
  // Show max 4 content tiles
  const contentTiles = content.slice(0, 4);
  return (
    <div className={s.root} ref={componentRef}>
      {(header || subHeader) && (
        <div className={s.heading}>
          {header && <div className={s.header}>{header}</div>}
          {subHeader && <div className={s.subHeader}>{subHeader}</div>}
        </div>
      )}
      <div className={s.contentTiles}>
        {contentTiles.map((contentItem, index) => (
          <ContentTile
            compact
            content={contentItem}
            key={contentItem.externalId}
            analyticsParams={{
              horizontalIndex: index,
              verticalIndex,
            }}
          />
        ))}
      </div>
    </div>
  );
};

FourTile.propTypes = propTypes;
export default FourTile;
