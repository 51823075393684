import React from 'react';
import PropTypes from 'prop-types';
import AnchorLink from '@components/AnchorLink';
import { trackClickEvent } from '@utils/analytics';
import { findUrl } from '@utils/hubs';
import s from './ContentTile.module.scss';

const propTypes = {
  content: PropTypes.shape({
    contentTypeId: PropTypes.string,
    externalId: PropTypes.string,
    displayTitle: PropTypes.string,
    eyebrow: PropTypes.string,
    url: PropTypes.string,
    label: PropTypes.string,
    mainImage: PropTypes.shape({
      image: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    tags: PropTypes.array,
  }).isRequired,
  compact: PropTypes.bool,
  analyticsParams: PropTypes.shape({
    verticalIndex: PropTypes.number.isRequired,
    horizontalIndex: PropTypes.number.isRequired,
  }),
};

const ContentTile = ({ content, analyticsParams = {}, compact = false }) => {
  const { displayTitle, mainImage, tags, externalId, url, contentTypeId, eyebrow, label } = content;
  const linkUrl = contentTypeId === 'article' ? findUrl(tags || [], externalId) : url;
  const { verticalIndex, horizontalIndex } = analyticsParams;

  return (
    <AnchorLink
      className={compact ? s.compact : ''}
      href={linkUrl}
      onClick={() => {
        trackClickEvent(
          {
            destination_url: linkUrl,
            label: displayTitle,
            location: 'Tile',
            vertical_index: verticalIndex,
            horizontal_index: horizontalIndex,
            content_name: displayTitle,
          },
          content,
        );
      }}
    >
      <div className={s.imageWrapper}>
        <img
          className={s.image}
          src={`${mainImage?.image?.file?.url}?w=370&h=210&fit=fill`}
          alt="Content"
        />
      </div>

      <div className={s.info}>
        {eyebrow && <div className={s.eyebrow}>{eyebrow}</div>}
        <div className={s.title}>{displayTitle || label}</div>
      </div>
      <div className={s.footer} />
    </AnchorLink>
  );
};

ContentTile.propTypes = propTypes;
export default ContentTile;
